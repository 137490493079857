var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-item"},[(!_vm.item.children)?_c('div',{staticClass:"tag",class:{
          'tag-selected' : _vm.isSelected,
          'tag-open' : _vm.isOpen,
          'tag-compact' : _vm.mode === 'selectedOnly'
       }},[(_vm.mode !== 'selectedOnly')?_c('span',{staticClass:"tag-checkbox",on:{"click":function($event){$event.stopPropagation();return _vm.toggleTagSelection(_vm.item)}}}):_vm._e(),_c('span',{staticClass:"tag-name"},[_vm._v(_vm._s(_vm.item.name)),(_vm.mode === 'selectedOnly')?_c('span',{staticClass:"remove-tag",on:{"click":function($event){$event.stopPropagation();return _vm.removeSelectedTag()}}}):_vm._e()])]):_c('div',{staticClass:"tag tag-has-children",class:{
        'tag-selected' : _vm.isSelected,
        'tag-open' : _vm.isOpen,
        'tag-compact' : _vm.mode === 'selectedOnly'
     },on:{"click":function($event){$event.stopPropagation();return _vm.toggle()}}},[(_vm.mode !== 'selectedOnly')?_c('span',{staticClass:"tag-checkbox",on:{"click":function($event){$event.stopPropagation();return _vm.toggleTagSelection(_vm.item)}}}):_vm._e(),_c('span',{staticClass:"tag-name"},[_vm._v(_vm._s(_vm.item.name)),(_vm.mode === 'selectedOnly')?_c('span',{staticClass:"remove-tag",on:{"click":function($event){$event.stopPropagation();return _vm.removeSelectedTag()}}}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"tag-children"},_vm._l((_vm.item.children),function(tag,index){return _c('choose-company-categories-tree-item',{key:'item_' + _vm.item.id + '_' + index,ref:"tagChildren",refInFor:true,attrs:{"item":tag,"mode":_vm.mode,"selectedTagIds":_vm.selectedTagIds},on:{"tagRemoved":function($event){return _vm.$emit('tagRemoved')},"checkboxSelected":function($event){return _vm.handleChildSelected($event)}}})}),1),_c('span',{staticClass:"fold-icon"})])])}
var staticRenderFns = []

export { render, staticRenderFns }